import { Buffer } from 'buffer';
import { User } from 'src/app/views/login/models/user';

export abstract class Utilities {
  public static toBase64(data: string): string {
    return Buffer.from(data).toString('base64');
  }

  private static transformString(dataString: string, positive: boolean): string {
    let stringFinal = '';
    for (const iteratorChar of dataString) {
      let numberChar: number = iteratorChar.charCodeAt(0);
      if (positive) {
        numberChar++;
      } else {
        numberChar--;
      }
      stringFinal += String.fromCharCode(numberChar);
    }
    return stringFinal;
  }

  public static transcribeUser(usuario: User): string {
    let sessionStorage = '';
    sessionStorage += String.fromCharCode(101);
    sessionStorage += this.transformString(usuario.mail, true);
    sessionStorage += '.:.';
    sessionStorage += this.transformString(usuario.token, true);
    sessionStorage += '.:.';
    sessionStorage += this.transformString(usuario.perm ? '1' : '0', true);
    return sessionStorage;
  }

  public static transcribeUserString(userString: string): User {
    const data = userString.split('.:.');
    const mail = this.transformString(data[0].substring(1), false);
    const token = this.transformString(data[1], false);
    const perm = this.transformString(data[2], false);
    const user: User = {
      mail: mail,
      token: token,
      perm: perm !== '0',
    };
    return user;
  }
}
