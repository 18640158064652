<mat-form-field appearance="standard">
  <mat-label>filtro</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Texto a buscar" #input />
</mat-form-field>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class="table-config-password" matSort hover>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
      <td mat-cell *matCellDef="let password">{{ password.id }}</td>
    </ng-container>
    <ng-container matColumnDef="platform">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Plataforma</th>
      <td mat-cell *matCellDef="let password">
        <a href="{{ password.url }}" target="_blank">{{ password.platform }} </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
      <td mat-cell *matCellDef="let password">
        <button
          type="button"
          class="btn btn-link"
          (click)="copyClipboard(password.user)"
          placement="top"
          ngbTooltip="Copiar en portapapeles">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-clipboard-plus"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
            <path
              d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
            <path
              d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
          </svg>
        </button>
        {{ password.user }}
      </td>
    </ng-container>
    <ng-container matColumnDef="valuePasswordFinal">
      <th mat-header-cell *matHeaderCellDef>Contraseña</th>
      <td mat-cell *matCellDef="let password" class="cursor-pointer" (click)="showHidePassword(password)">
        {{ password.valuePasswordFinal }}
        <button
          *ngIf="!password.passwordHide"
          type="button"
          class="btn btn-link"
          (click)="copyClipboard(password.valuePassword)"
          placement="top"
          ngbTooltip="Copiar en portapapeles">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-clipboard-plus"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
            <path
              d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
            <path
              d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
          </svg>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="shared" *ngIf="user !== undefined && user.perm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Compartida</th>
      <td mat-cell *matCellDef="let password">{{ password.shared ? 'Sí' : 'No' }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        Acciones
        <button mat-raised-button (click)="openModal(modalEditPassword, false, true, null)" class="new-button">
          Nuevo
        </button>
      </th>
      <td mat-cell *matCellDef="let password">
        <button
          *ngIf="user !== undefined && user.mail === password.userMail"
          type="button"
          class="btn btn-link"
          (click)="openModal(modalEditPassword, false, false, password)"
          placement="top"
          ngbTooltip="Editar la estructura de la contraseña">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pencil-square"
            viewBox="0 0 16 16">
            <path
              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path
              fill-rule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
          </svg>
        </button>
        <button
          *ngIf="user !== undefined && user.mail === password.userMail"
          type="button"
          class="btn btn-link"
          (click)="deletePassword(password)"
          placement="top"
          ngbTooltip="Borrar contraseña">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-trash3"
            viewBox="0 0 16 16">
            <path
              d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
          </svg>
        </button>
        <button
          *ngIf="user !== undefined && user.perm && user.mail === password.userMail"
          type="button"
          class="btn btn-link"
          (click)="sharedPassword(password)"
          placement="top"
          [ngbTooltip]="!password.shared ? 'Compartir con el resto de usuarios' : 'Dejas de compartir'">
          <svg
            *ngIf="!password.shared"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-share"
            viewBox="0 0 16 16">
            <path
              d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
          </svg>
          <svg
            *ngIf="password.shared"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-share-fill"
            viewBox="0 0 16 16">
            <path
              d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
          </svg>
        </button>
        <button
          *ngIf="user !== undefined && user.mail === password.userMail"
          type="button"
          class="btn btn-link"
          (click)="openModal(modalEditPassword, true, false, password)"
          placement="top"
          ngbTooltip="Personalizar contraseña">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-book-half"
            viewBox="0 0 16 16">
            <path
              d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
          </svg>
        </button>
        <button
          *ngIf="user !== undefined && user.mail === password.userMail"
          type="button"
          class="btn btn-link"
          (click)="newPasswordRandom(password)"
          placement="top"
          ngbTooltip="Crear nueva contraseña aleatoria">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-clockwise"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
            <path
              d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
          </svg>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro "{{ input.value }}"</td>
    </tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100]"
    [pageSize]="10"
    [length]="dataSource.data.length"></mat-paginator>
</div>

<!-- Modal -->
<ng-template #modalEditPassword>
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="modal-title">{{ titleModal }}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="bodyModal">
      <div [ngSwitch]="step">
        <div *ngSwitchCase="'UpdateAllData'">
          <form [formGroup]="formPassword" (ngSubmit)="onSubmit()">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon-platform">Plataforma</span>
              <input
                type="text"
                class="form-control"
                placeholder="Plataforma"
                formControlName="platform"
                aria-label="platform"
                aria-describedby="basic-addon-platform"
                maxlength="50" />
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon-url">Enlace</span>
              <input
                type="text"
                class="form-control"
                placeholder="Enlace"
                formControlName="url"
                aria-label="url"
                aria-describedby="basic-addon-url"
                maxlength="200" />
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon-user">Usuario</span>
              <input
                type="text"
                class="form-control"
                placeholder="Usuario"
                formControlName="user"
                aria-label="user"
                aria-describedby="basic-addon-user"
                maxlength="50" />
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon-sizePassword">Longitud</span>
              <input
                type="number"
                class="form-control"
                placeholder="Longitud"
                formControlName="sizePassword"
                aria-label="sizePassword"
                aria-describedby="basic-addon-sizePassword"
                ngbTooltip="4-50" />
            </div>

            <button
              type="submit"
              class="btn btn-success text-uppercase mb-2 rounded-pill shadow-sm"
              [disabled]="formPassword.invalid">
              {{ buttonModal }}
            </button>
          </form>
        </div>
        <div *ngSwitchCase="'UpdateCustomPassword'">
          <form [formGroup]="formCustomPassword" (ngSubmit)="onSubmit()">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon-valuePassword">Contraseña</span>
              <input
                type="text"
                class="form-control"
                placeholder="Contraseña personalizada"
                formControlName="valuePassword"
                aria-label="valuePassword"
                aria-describedby="basic-addon-valuePassword"
                maxlength="50" />
            </div>

            <button
              type="submit"
              class="btn btn-success text-uppercase mb-2 rounded-pill shadow-sm"
              [disabled]="formCustomPassword.invalid">
              {{ buttonModal }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
