import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { INavData } from '@coreui/angular';
import { Utilities } from 'src/app/core/utilities/utilities';
import { User } from '../../login/models/user';
import { SessionService } from 'src/app/core/services/control/session.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  public user: User | undefined;
  public navItems: INavData[];

  public isChecked: boolean;

  constructor(private Router: Router, private SessionService: SessionService) {
    const userString = sessionStorage.getItem('user');
    if (userString !== null) {
      this.user = Utilities.transcribeUserString(userString);
    }
    this.navItems = [];
    this.isChecked = false;
  }

  ngOnInit() {
    this.buildNavItems();

    setInterval(() => {
      if (this.isChecked) {
        this.SessionService.sessionActive().subscribe((response: any) => {
          console.log('Petción para mantener sesión activa:', response);

          setTimeout(() => {
            console.log('Reiniciando intervalo después de 30 segundos');
          }, 30 * 1000);
        });
      }
    }, 9 * 60 * 1000 + 30 * 1000);
  }

  private buildNavItems(): void {
    let inavDataItemsUtilities: INavData[] = [];
    if (this.user?.perm) {
      inavDataItemsUtilities.push(
        this.buildNavItem(false, 'Gestor de archivos', { name: 'cil-folder-open' }, 'config-files')
      );
    }
    inavDataItemsUtilities.push(
      this.buildNavItem(false, 'Gestión de contraseñas', { name: 'cib-keycdn' }, 'config-password')
    );
    if (this.user?.perm) {
      inavDataItemsUtilities.push(
        this.buildNavItem(false, 'Contraseñas compartidas', { name: 'cib-keycdn' }, 'password-shared')
      );
    }
    this.navItems.push(
      this.buildNavItem(false, 'Utilidades', { name: 'cib-server-fault' }, undefined, inavDataItemsUtilities)
    );
    if (this.user?.perm) {
      let inavDataItemsAppsBackSKD: INavData[] = [];
      inavDataItemsAppsBackSKD.push(this.buildNavItem(false, 'Log', { name: 'cil-av-timer' }, 'back-skd-log'));
      let inavDataItemsAppsOverturn: INavData[] = [];
      inavDataItemsAppsOverturn.push(this.buildNavItem(false, 'Log', { name: 'cil-av-timer' }, 'overturn-log'));
      let inavDataItemsAppsTwitter: INavData[] = [];
      inavDataItemsAppsTwitter.push(this.buildNavItem(false, 'Log', { name: 'cil-av-timer' }, 'twitter-log'));
      let inavDataItemsAppsTwitch: INavData[] = [];
      inavDataItemsAppsTwitch.push(this.buildNavItem(false, 'Log', { name: 'cil-av-timer' }, 'twitch-log'));
      let inavDataItemsAppsControl: INavData[] = [];
      inavDataItemsAppsControl.push(this.buildNavItem(false, 'Log', { name: 'cil-av-timer' }, 'control-log'));
      let inavDataItemsApps: INavData[] = [];
      inavDataItemsApps.push(
        this.buildNavItem(false, 'Back_SKD', { name: 'cil-av-timer' }, undefined, inavDataItemsAppsBackSKD)
      );
      inavDataItemsApps.push(
        this.buildNavItem(false, 'Overturn', { name: 'cil-av-timer' }, undefined, inavDataItemsAppsOverturn)
      );
      inavDataItemsApps.push(
        this.buildNavItem(false, 'Twitter', { name: 'cil-av-timer' }, undefined, inavDataItemsAppsTwitter)
      );
      inavDataItemsApps.push(
        this.buildNavItem(false, 'Twitch', { name: 'cil-av-timer' }, undefined, inavDataItemsAppsTwitch)
      );
      inavDataItemsApps.push(
        this.buildNavItem(false, 'Control', { name: 'cil-av-timer' }, undefined, inavDataItemsAppsControl)
      );
      this.navItems.push(
        this.buildNavItem(false, 'Gestión (Aplicaciones)', { name: 'cil-apps-settings' }, undefined, inavDataItemsApps)
      );
    }
    let inavDataItemsConfig: INavData[] = [];
    inavDataItemsConfig.push(this.buildNavItem(false, 'Streamers Twitch', { name: 'cib-twitch' }, 'streamers-twitch'));
    inavDataItemsConfig.push(this.buildNavItem(false, 'Configuración', { name: 'cib-atom' }, 'settings'));
    this.navItems.push(
      this.buildNavItem(false, 'Gestión general', { name: 'cil-settings' }, undefined, inavDataItemsConfig)
    );
    this.navItems.push(this.buildNavItem(false, 'Logout', { name: 'cil-account-logout' }, ''));
  }

  private buildNavItem(
    title: boolean,
    name: string,
    iconInfo?: any,
    routerUrl?: string,
    children?: INavData[]
  ): INavData {
    let inavData: INavData = { title: title, name: name, iconComponent: iconInfo, url: routerUrl, children: children };
    return inavData;
  }

  onCheckboxChange(): void {
    this.isChecked = !this.isChecked;
  }
}
