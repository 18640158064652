<mat-form-field appearance="standard">
  <mat-label>filtro</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Texto a buscar" #input />
</mat-form-field>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class="table-config-password" matSort hover>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
      <td mat-cell *matCellDef="let password">{{ password.id }}</td>
    </ng-container>
    <ng-container matColumnDef="platform">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Plataforma</th>
      <td mat-cell *matCellDef="let password">
        <a href="{{ password.url }}" target="_blank">{{ password.platform }} </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
      <td mat-cell *matCellDef="let password">
        <button
          type="button"
          class="btn btn-link"
          (click)="copyClipboard(password.user)"
          placement="top"
          ngbTooltip="Copiar en portapapeles">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-clipboard-plus"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
            <path
              d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
            <path
              d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
          </svg>
        </button>
        {{ password.user }}
      </td>
    </ng-container>
    <ng-container matColumnDef="valuePasswordFinal">
      <th mat-header-cell *matHeaderCellDef>Contraseña</th>
      <td mat-cell *matCellDef="let password" class="cursor-pointer" (click)="showHidePassword(password)">
        {{ password.valuePasswordFinal }}
        <button
          *ngIf="!password.passwordHide"
          type="button"
          class="btn btn-link"
          (click)="copyClipboard(password.valuePassword)"
          placement="top"
          ngbTooltip="Copiar en portapapeles">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-clipboard-plus"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
            <path
              d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
            <path
              d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
          </svg>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro "{{ input.value }}"</td>
    </tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100]"
    [pageSize]="10"
    [length]="dataSource.data.length"></mat-paginator>
</div>
