// Modules
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

// Models
import { Password } from '../../config-password/models/password';

// Services
import { NgxSpinnerService } from 'ngx-spinner';
import { PasswordService } from '../../config-password/services/password.service';
import { ClipboardService } from 'ngx-clipboard';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-password-shared',
  templateUrl: './password-shared.component.html',
  styleUrls: ['./password-shared.component.css'],
  providers: [PasswordService],
})
export class PasswordSharedComponent implements OnInit, AfterViewInit {
  public passwordListShared: Password[] = [];
  public displayedColumns: string[] = ['id', 'platform', 'user', 'valuePasswordFinal'];
  public dataSource: MatTableDataSource<Password> = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort = new MatSort();
  constructor(
    private NgxSpinnerService: NgxSpinnerService,
    private PasswordService: PasswordService,
    private ClipboardService: ClipboardService
  ) {}

  ngOnInit() {
    this.getPasswords();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.selectConfig = {};
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  private getPasswords(): void {
    this.NgxSpinnerService.show();
    this.PasswordService.getPasswordsShared()?.subscribe({
      next: (response: Password[]) => {
        this.passwordListShared = this.buildListPassword(response);
        this.setDataSource();
      },
      error: () => {
        this.NgxSpinnerService.hide();
      },
      complete: () => {
        this.NgxSpinnerService.hide();
      },
    });
  }

  private replacePassword(password: string): string {
    return password.replace(/./g, '*');
  }

  private buildListPassword(listPassword: Password[]): Password[] {
    let list: Password[] = [];
    listPassword.forEach(elementPass => {
      elementPass.valuePasswordFinal = this.replacePassword(elementPass.valuePassword);
      elementPass.passwordHide = true;
      list.push(elementPass);
    });
    return list;
  }

  public showHidePassword(password: Password): void {
    password.passwordHide = !password.passwordHide;
    if (password.passwordHide) {
      password.valuePasswordFinal = this.replacePassword(password.valuePassword);
    } else {
      password.valuePasswordFinal = password.valuePassword;
    }
    if (!password.passwordHide) {
      this.dataSource.data.forEach(elementPassword => {
        if (elementPassword.id !== password.id) {
          elementPassword.passwordHide = true;
          elementPassword.valuePasswordFinal = this.replacePassword(elementPassword.valuePassword);
        }
      });
    }
  }

  public copyClipboard(text: string) {
    this.ClipboardService.copy(text);
  }

  private setDataSource(): void {
    this.dataSource.data = this.passwordListShared;
    if (this.dataSource.sort) {
      this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    }
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
