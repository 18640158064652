import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { OverturnService } from '../../service/overturn.service';

@Component({
  selector: 'app-overturn-log',
  templateUrl: './overturn-log.component.html',
  styleUrls: ['./overturn-log.component.css'],
  providers: [OverturnService],
})
export class OverturnLogComponent implements OnInit, OnDestroy {
  public logLines: string[] = [];
  private intervalSubscription: Subscription | undefined;

  @ViewChild('console') consoleContentOverturnLog: ElementRef | undefined;
  constructor(private OverturnService: OverturnService) {}

  ngOnInit() {
    const intervalObservable = interval(2000);
    this.intervalSubscription = intervalObservable.subscribe(() => {
      this.OverturnService.getLog()?.subscribe((res: any[]) => {
        this.logLines.push(...res);
        this.logLines = [...new Set(this.logLines)];
      });
    });
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}
