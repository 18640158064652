import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { BackSkdService } from '../../service/back-skd.service';

@Component({
  selector: 'app-back-skd-log',
  templateUrl: './back-skd-log.component.html',
  styleUrls: ['./back-skd-log.component.css'],
  providers: [BackSkdService],
})
export class BackSkdLogComponent implements OnInit, OnDestroy, AfterViewInit {
  public logLines: string[] = [];
  private intervalSubscription: Subscription | undefined;

  @ViewChild('console') consoleContentBackSkdLog: ElementRef | undefined;
  constructor(private BackSkdService: BackSkdService) {}

  ngOnInit() {
    const intervalObservable = interval(2000);
    this.intervalSubscription = intervalObservable.subscribe(() => {
      this.BackSkdService.getLog()?.subscribe((res: any[]) => {
        this.logLines.push(...res);
        this.logLines = [...new Set(this.logLines)];
      });
    });
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    const consoleDiv = this.consoleContentBackSkdLog?.nativeElement;
    consoleDiv.scrollTop = consoleDiv.scrollHeight;
  }
}
