// Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { Streamer } from '../models/streamer';

// Services
import { HttpService } from 'src/app/core/services/http/http.service';
import { RelativeUrl } from 'src/app/core/utilities/constants';

@Injectable({
  providedIn: 'root',
})
export class StreamerService extends HttpService {
  public constructor(private http: HttpClient) {
    super(http);
  }

  public getStreamers(): Observable<Streamer[]> | null {
    const service: string = 'administration/streamers';
    return this._requestGet(RelativeUrl.STREAMER + service);
  }
}
