// Modules
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// Models
import { FileSystem } from '../models/file-system';

// Services
import { FilesService } from '../services/files.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, ReplaySubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-config-files',
  templateUrl: './config-files.component.html',
  styleUrls: ['./config-files.component.css'],
  providers: [FilesService],
})
export class ConfigFilesComponent implements OnInit {
  public displayedColumns: string[] = [
    'nameFile',
    'directory',
    'empty',
    'size',
    'actions',
    'creationDate',
    'lastAccessDate',
    'lastModifiedDate',
  ];
  public dataSource: MatTableDataSource<FileSystem> = new MatTableDataSource();
  public fileList: FileSystem[] = [];
  private pathInit: string = '';
  private pathAux: string | undefined;
  public notInitPath: boolean = false;

  private pathInInit: string = '/';
  public pathInServer: string = this.pathInInit;
  public pathBase: string = '/var/www/Stalking_Dragons_FILES/uploads';
  public pathImg: string = '/var/www/Stalking_Dragons_FILES/uploads/Imagenes';

  public titleModal: string = '';
  public step: string = '';
  public modalRef?: BsModalRef;
  public buttonModal: string = '';

  private fileAux: FileSystem | undefined;

  public newFolderToCreate: string = '';
  private fileSystemAux: FileSystem | undefined;

  constructor(
    private BsModalService: BsModalService,
    private NgxSpinnerService: NgxSpinnerService,
    private FilesService: FilesService
  ) {}

  ngOnInit() {
    this.getInitialPathData();
  }

  private setDataSource(): void {
    let fileSytem: FileSystem[] = [];
    let backOffActive: boolean = false;
    if (this.pathInServer !== this.pathInInit) {
      backOffActive = true;
    }
    fileSytem.push({ nameFile: '...', backOff: true, backOffActive: backOffActive });
    fileSytem.concat(this.fileList);
    this.dataSource.data = fileSytem.concat(this.fileList);
  }

  private getInitialPathData(): void {
    this.NgxSpinnerService.show();
    this.FilesService.getInitialPathData()?.subscribe({
      next: (fileListResponse: FileSystem[]) => {
        const fileListRes: FileSystem[] = fileListResponse;
        if (fileListRes.length > 0 && fileListRes[0].path !== undefined) {
          this.pathInit = fileListRes[0].path;
        }
        this.fileList = fileListRes.map((fileSystem: FileSystem) => ({
          ...fileSystem,
          backOff: false,
        }));
        this.setDataSource();
      },
      error: () => {
        this.NgxSpinnerService.hide();
      },
      complete: () => {
        this.NgxSpinnerService.hide();
      },
    });
  }

  private buidlPathInServer(fileSystem: FileSystem): void {
    let path = fileSystem.path?.replace(this.pathBase, '');
    if (path != undefined) {
      if (fileSystem.nameFile !== undefined) {
        path += '/' + fileSystem.nameFile;
      }
      this.pathInServer = path.replace(this.pathBase, '');
    }
  }

  public openFolder(fileSytem: FileSystem): void {
    this.NgxSpinnerService.show();
    this.FilesService.getDataFromFolder(fileSytem)?.subscribe({
      next: (fileListResponse: FileSystem[]) => {
        this.notInitPath = true;
        this.buidlPathInServer(fileSytem);
        const fileListRes: FileSystem[] = fileListResponse;
        this.pathAux = fileListRes[0].path;
        this.fileList = fileListRes.map((fileSystem: FileSystem) => ({
          ...fileSystem,
          backOff: false,
        }));
        this.setDataSource();
      },
      error: () => {
        this.NgxSpinnerService.hide();
      },
      complete: () => {
        this.NgxSpinnerService.hide();
      },
    });
  }

  public returnFolder(): void {
    let fileSytem: FileSystem = { nameFile: '', backOff: false, backOffActive: false };
    if (this.pathAux?.includes('\\')) {
      const pathArray = this.pathAux.split('\\');
      pathArray?.pop();
      fileSytem.path = pathArray?.toString().replaceAll(',', '\\');
    } else {
      const pathArray = this.pathAux?.split('/');
      pathArray?.pop();
      fileSytem.path = pathArray?.toString().replaceAll(',', '/');
    }
    this.NgxSpinnerService.show();
    if (this.pathInit === fileSytem.path) {
      this.getInitialPathData();
      this.notInitPath = false;
    } else {
      this.openFolder(fileSytem);
      this.notInitPath = true;
    }
    this.buidlPathInServer(fileSytem);
  }

  public uploadFileToFolder(template: TemplateRef<any>, fileSytem: FileSystem) {
    this.step = 'UploadFile';
    this.titleModal = 'Subir un archivo a la carpeta ' + fileSytem.nameFile;
    this.buttonModal = 'Upload file';
    this.fileAux = {
      directory: false,
      path: fileSytem.path,
      backOff: false,
      backOffActive: false,
    };
    this.fileAux.directory = false;
    if (fileSytem.path?.includes('\\')) {
      this.fileAux.pathToUpload = fileSytem.path + '\\' + fileSytem.nameFile;
    } else {
      this.fileAux.pathToUpload = fileSytem.path + '/' + fileSytem.nameFile;
    }

    this.modalRef = this.BsModalService.show(template, {
      ariaDescribedby: 'my-modal-description',
      ariaLabelledBy: 'my-modal-title',
    });
  }

  private convertFile(file: File): Observable<any> {
    const result = new ReplaySubject<any>(1);
    const reader = new FileReader();
    if (this.fileAux !== undefined) {
      this.fileAux.nameFile = file.name;
      reader.readAsDataURL(file);
      reader.onload = () => {
        result.next(reader?.result as string);
        result.complete();
      };
    }
    return result;
  }

  public onFileSelected(event: any): void {
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      if (this.fileAux !== undefined) {
        this.fileAux.base64 = base64;
      }
    });
  }

  public onSubmit(): void {
    switch (this.step) {
      case 'UploadFile':
        this.NgxSpinnerService.show();
        if (this.fileAux !== undefined) {
          this.FilesService.uploadFile(this.fileAux)?.subscribe({
            next: (fileListResponse: FileSystem[]) => {
              this.fileList = fileListResponse;
            },
            error: () => {
              this.modalRef?.hide();
              this.NgxSpinnerService.hide();
            },
            complete: () => {
              this.modalRef?.hide();
              this.NgxSpinnerService.hide();
            },
          });
        }
        break;
      case 'CreateFolder':
        this.NgxSpinnerService.show();
        const fileSystem: FileSystem = {
          path: this.pathAux !== undefined ? this.pathAux : this.pathInit,
          newFolderToCreate: this.newFolderToCreate,
          backOff: false,
          backOffActive: false,
        };
        this.FilesService.createFolder(fileSystem)?.subscribe({
          next: (fileListResponse: FileSystem[]) => {
            this.fileList = fileListResponse;
          },
          error: () => {
            this.modalRef?.hide();
            this.NgxSpinnerService.hide();
          },
          complete: () => {
            this.setDataSource();
            this.modalRef?.hide();
            this.NgxSpinnerService.hide();
          },
        });
        break;
      case 'CreateFolderSelected':
        this.NgxSpinnerService.show();
        const fileSystemSelected: FileSystem = {
          path: this.pathAux !== undefined ? this.pathAux + '/' + this.fileSystemAux?.nameFile : this.pathInit,
          newFolderToCreate: this.newFolderToCreate,
          backOff: false,
          backOffActive: false,
        };
        this.FilesService.createFolder(fileSystemSelected)?.subscribe({
          next: (fileListResponse: FileSystem[]) => {
            this.fileList = fileListResponse;
            this.pathAux = this.fileList[0].path;
            this.pathInServer = this.pathAux !== undefined ? this.pathAux.replace(this.pathBase, '') : '/';
          },
          error: () => {
            this.modalRef?.hide();
            this.NgxSpinnerService.hide();
          },
          complete: () => {
            this.setDataSource();
            this.modalRef?.hide();
            this.NgxSpinnerService.hide();
          },
        });
        break;

      default:
        break;
    }
  }

  public downloadFile(fileSytem: FileSystem): void {
    this.NgxSpinnerService.show();
    this.FilesService.downloadFile(fileSytem)?.subscribe({
      next: (fileResponse: FileSystem) => {
        if (fileResponse.nameFile != undefined) {
          const downloadLink = document.createElement('a');
          const href = 'data:' + fileResponse.mimeType + ';base64,' + fileResponse.base64;
          downloadLink.href = href;
          downloadLink.download = fileResponse.nameFile;
          downloadLink.click();
        }
      },
      error: () => {
        this.NgxSpinnerService.hide();
      },
      complete: () => {
        this.NgxSpinnerService.hide();
      },
    });
  }

  public deletefile(fileSytem: FileSystem): void {
    this.NgxSpinnerService.show();
    this.FilesService.deleteFile(fileSytem)?.subscribe({
      next: (fileListResponse: FileSystem[]) => {
        this.fileList = fileListResponse;
      },
      error: () => {
        this.modalRef?.hide();
        this.NgxSpinnerService.hide();
      },
      complete: () => {
        if (this.fileList.length > 0) {
          this.pathAux = this.fileList[0].path;
          this.pathInServer = this.pathAux !== undefined ? this.pathAux.replace(this.pathBase, '') : '/';
          this.setDataSource();
        } else {
          let fileSytem: FileSystem = { nameFile: '', backOff: false, backOffActive: false };
          if (this.pathAux?.includes('\\')) {
            const pathArray = this.pathAux.split('\\');
            pathArray?.pop();
            fileSytem.path = pathArray?.toString().replaceAll(',', '\\');
          } else {
            const pathArray = this.pathAux?.split('/');
            pathArray?.pop();
            fileSytem.path = pathArray?.toString().replaceAll(',', '/');
          }
          this.openFolder(fileSytem);
        }
        this.modalRef?.hide();
        this.NgxSpinnerService.hide();
      },
    });
  }

  public openFile(fileSytem: FileSystem): void {
    let url = 'http://imagenes.skdragons.com/';
    if (fileSytem.path?.includes(this.pathImg)) {
      const urlExtra = fileSytem.path.replace(this.pathImg, '');
      const downloadLink = document.createElement('a');
      downloadLink.href = url + (urlExtra.length > 0 ? urlExtra + '/' : '') + fileSytem.nameFile;
      downloadLink.target = '_blank';
      downloadLink.click();
    }
  }

  public createFolder(template: TemplateRef<any>) {
    this.step = 'CreateFolder';
    this.titleModal = 'Crear una nueva carpeta';
    this.buttonModal = 'Crear carpeta';
    this.modalRef = this.BsModalService.show(template, {
      ariaDescribedby: 'my-modal-description',
      ariaLabelledBy: 'my-modal-title',
    });
  }

  public createFolderSelected(template: TemplateRef<any>, fileSystem: FileSystem) {
    this.step = 'CreateFolderSelected';
    this.titleModal = 'Crear una nueva carpeta';
    this.buttonModal = 'Crear carpeta';
    this.fileSystemAux = fileSystem;
    this.modalRef = this.BsModalService.show(template, {
      ariaDescribedby: 'my-modal-description',
      ariaLabelledBy: 'my-modal-title',
    });
  }
}
