import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Streamer } from '../models/streamer';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { StreamerService } from '../services/streamer.service';

@Component({
  selector: 'app-streamers',
  templateUrl: './streamers.component.html',
  styleUrls: ['./streamers.component.css'],
})
export class StreamersComponent implements OnInit, AfterViewInit {
  public dataSource: MatTableDataSource<Streamer>;
  public displayedColumns: string[];

  private streamerList: Streamer[];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort = new MatSort();
  constructor(private NgxSpinnerService: NgxSpinnerService, private StreamerService: StreamerService) {
    this.dataSource = new MatTableDataSource();
    this.displayedColumns = [
      'id',
      'active',
      'streamerName',
      'direct',
      'twitchUserId',
      'twitchUserLogin',
      'twitchName',
      'urlTwitter',
      'urlYoutube',
      'urlFacebook',
      'urlInstagram',
      'urlServerDiscord',
      'thumbnailUrl',
      'userImg',
      'customImg',
      'mail',
      'lastBroadcastTwitch',
      'createdDate',
      'tweetsent',
      'languageValue',
      'actions',
    ];
    this.streamerList = [];
  }

  ngOnInit() {
    this.getStreamers();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.selectConfig = {};
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  private buildLisStreamer(listStreamer: Streamer[]): void {
    this.streamerList = [...listStreamer];
    this.setDataSource();
  }

  private getStreamers(): void {
    this.NgxSpinnerService.show();
    this.StreamerService.getStreamers()?.subscribe({
      next: (response: Streamer[]) => {
        this.buildLisStreamer(response);
      },
      error: () => {
        this.NgxSpinnerService.hide();
      },
      complete: () => {
        this.NgxSpinnerService.hide();
      },
    });
  }

  private setDataSource(): void {
    this.dataSource.data = this.streamerList;
    if (this.dataSource.sort) {
      this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    }
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
