export class RelativeUrl {
  static readonly ACCESS_ADMINISTRATION: string = '/access/web/administration/';
  static readonly ACCESS_LOGIN_ADMINISTRATION: string = '/access/login/administration/';
  static readonly PASSWORD: string = '/password/';
  static readonly STREAMER: string = '/streamer/';
  static readonly CONTROL: string = '/control/';
  static readonly ACCESS_LOGIN_ADMINISTRATION_RESETPASSWORD: string = '/access/login/administration/resetpassword';
  static readonly FILE: string = '/file';
  static readonly GET_USERS: string = '/twitter/users/';
  static readonly GET_BACK_SKD_LOG: string = '/log/';
  static readonly GET_OVERTURN_LOG: string = '/log/overturn/';
  static readonly GET_TWITTER_LOG: string = '/log/twitter/';
  static readonly GET_TWITCH_LOG: string = '/log/twitch/';
  static readonly GET_CONTROL_LOG: string = '/log/control/';
}

export class CONSTANTS {
  static readonly WEB_ADMINISTRATION_LITERAL: string = 'Web_Administration';
  static readonly ACCESS_AUTH_LITERAL: string = 'Access-Auth';
  static readonly TOKEN_AUTH_LITERAL: string = 'Token-Auth';
  static readonly CONTENT_TYPE_LITERAL: string = 'Content-Type';
  static readonly CONTENT_TYPE_JSON: string = 'application/json';
}

export class HttpCode {
  static readonly UNAUTHORIZED: number = 401;
  static readonly UNAVAILABLE_FOR_LEGAL_REASONS: number = 451;
}
