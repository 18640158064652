<div class="container-fluid">
  <div class="row no-gutter">
    <!-- The image half -->
    <div class="col-md-6 d-none d-md-flex bg-image"></div>
    <!-- The content half -->
    <div class="col-md-6 bg-light">
      <div class="login d-flex align-items-center py-5">
        <!-- Demo content-->
        <div class="container">
          <div class="row">
            <div class="col-lg-10 col-xl-7 mx-auto">
              <h3 class="display-4">Login</h3>
              <form [formGroup]="formLogin" (ngSubmit)="onSubmit()">
                <div class="form-group mb-3">
                  <input
                    id="inputMailLogin"
                    type="email"
                    formControlName="mailLogin"
                    [ngClass]="{ 'is-invalid': submitted && form['mailLogin'].errors }"
                    placeholder="Email address"
                    required=""
                    autofocus=""
                    class="form-control rounded-pill border-0 shadow-sm px-4" />
                  <div *ngIf="submitted && form['mailLogin'].errors" class="invalid-feedback">
                    <div *ngIf="form['mailLogin'].errors['required']">Email es obligatorio</div>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <input
                    id="inputPasswordLogin"
                    type="password"
                    formControlName="passwordLogin"
                    [ngClass]="{ 'is-invalid': submitted && form['passwordLogin'].errors }"
                    placeholder="Password"
                    required=""
                    class="form-control rounded-pill border-0 shadow-sm px-4 text-primary" />
                  <div *ngIf="submitted && form['passwordLogin'].errors" class="invalid-feedback">
                    <div *ngIf="form['passwordLogin'].errors['required']">Password es obligatorio</div>
                  </div>
                </div>

                <div class="form-group mb-3">
                  <div class="ps-4">
                    <a href="#" class="link-primary" (click)="resetPassword()">Recuperar contraseña</a>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
        <!-- End -->
      </div>
    </div>
    <!-- End -->
  </div>
</div>
