import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [],
  imports: [NgbModule, ModalModule.forRoot()],
  exports: [NgbModule, ModalModule],
  providers: [],
  bootstrap: [],
})
export class BootstrapCustomModule {}
