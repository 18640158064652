// Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { FileSystem } from '../models/file-system';

// Services
import { HttpService } from 'src/app/core/services/http/http.service';
import { RelativeUrl } from 'src/app/core/utilities/constants';

@Injectable({
  providedIn: 'root',
})
export class FilesService extends HttpService {
  public constructor(private http: HttpClient) {
    super(http);
  }

  public getInitialPathData(): Observable<FileSystem[]> | null {
    return this._requestGet(RelativeUrl.FILE + '/');
  }

  public getDataFromFolder(fileSystem: FileSystem): Observable<FileSystem[]> | null {
    return this._requestPost(RelativeUrl.FILE + '/', fileSystem);
  }

  public createFolder(fileSystem: FileSystem): Observable<FileSystem[]> | null {
    return this._requestPost(RelativeUrl.FILE + '/createfolder', fileSystem);
  }

  public uploadFile(fileSystem: FileSystem): Observable<FileSystem[]> | null {
    return this._requestPut(RelativeUrl.FILE + '/', fileSystem);
  }

  public deleteFile(fileSystem: FileSystem): Observable<FileSystem[]> | null {
    if (fileSystem.path !== undefined) {
      const service: string = '?path=' + encodeURIComponent(fileSystem.path) + '&file=' + fileSystem.nameFile;
      return this._requestDelete(RelativeUrl.FILE + service);
    } else {
      return null;
    }
  }

  public downloadFile(fileSystem: FileSystem): Observable<FileSystem> | null {
    return this._requestPost(RelativeUrl.FILE + '/downloadfile', fileSystem);
  }
}
