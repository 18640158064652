// Modules
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

// Models
import { User } from 'src/app/views/login/models/user';
import { Password } from '../models/password';

// Services
import { NgxSpinnerService } from 'ngx-spinner';
import { Utilities } from 'src/app/core/utilities/utilities';
import { PasswordService } from '../services/password.service';
import { ClipboardService } from 'ngx-clipboard';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-config-password',
  templateUrl: './config-password.component.html',
  styleUrls: ['./config-password.component.css'],
  providers: [PasswordService],
})
export class ConfigPasswordComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = [];
  public dataSource: MatTableDataSource<Password>;
  private create: boolean = true;
  private sizePasswordTemp: number = 0;
  private idTemp: number = 0;
  public titleModal: string = '';
  public buttonModal: string = '';
  public formPassword: FormGroup = new FormGroup({
    platform: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    url: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    user: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    sizePassword: new FormControl('', [Validators.required, Validators.min(4), Validators.max(50)]),
  });
  public formCustomPassword: FormGroup = new FormGroup({
    id: new FormControl('', [Validators.required]),
    valuePassword: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
  });
  private passwordAux: Password | undefined;
  public passwordList: Password[] = [];
  public submitted = false;
  public modalRef?: BsModalRef;
  public step: string = '';
  public user: User | undefined;

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort = new MatSort();
  constructor(
    private BsModalService: BsModalService,
    private NgxSpinnerService: NgxSpinnerService,
    private PasswordService: PasswordService,
    private ClipboardService: ClipboardService
  ) {
    this.dataSource = new MatTableDataSource();
    const userString = sessionStorage.getItem('user');
    if (userString !== null) {
      this.user = Utilities.transcribeUserString(userString);
      if (this.user.perm) {
        this.displayedColumns = ['id', 'platform', 'user', 'valuePasswordFinal', 'shared', 'actions'];
      } else {
        this.displayedColumns = ['id', 'platform', 'user', 'valuePasswordFinal', 'actions'];
      }
    }
  }

  ngOnInit() {
    this.getPasswords();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.selectConfig = {};
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public get form() {
    return this.formPassword.controls;
  }

  public openModal(template: TemplateRef<any>, custom: boolean, create: boolean, password: Password | null): void {
    if (!custom) {
      this.step = 'UpdateAllData';
      if (password !== null) {
        this.editPassword(password);
      } else {
        this.formPassword.reset();
      }
      this.create = create;
      this.titleModal = create ? 'Nueva contraseña' : 'Editar contraseña';
      this.buttonModal = create ? 'Guardar' : 'Actualizar';
      this.modalRef = this.BsModalService.show(template, {
        ariaDescribedby: 'my-modal-description',
        ariaLabelledBy: 'my-modal-title',
      });
    } else {
      this.passwordAux = password ?? undefined;
      this.formCustomPassword.patchValue({
        id: password?.id,
      });
      this.step = 'UpdateCustomPassword';
      this.titleModal = 'Editar contraseña';
      this.buttonModal = 'Actualizar';
      this.modalRef = this.BsModalService.show(template, {
        ariaDescribedby: 'my-modal-description',
        ariaLabelledBy: 'my-modal-title',
      });
    }
  }

  private replacePassword(password: string): string {
    return password.replace(/./g, '*');
  }

  private formatPassword(elementPass: Password): void {
    elementPass.valuePasswordFinal = this.replacePassword(elementPass.valuePassword);
    elementPass.passwordHide = true;
  }

  private setDataSource(): void {
    this.dataSource.data = this.passwordList;
    if (this.dataSource.sort) {
      this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    }
  }

  private buildListPassword(listPassword: Password[]): void {
    let list: Password[] = [];
    listPassword.forEach(elementPass => {
      this.formatPassword(elementPass);
      list.push(elementPass);
    });
    this.passwordList = [...list];
    this.setDataSource();
  }

  private getPasswords(): void {
    if (this.user !== undefined) {
      this.NgxSpinnerService.show();
      if (this.user.perm) {
        this.PasswordService.getPasswordsAdmin(this.user.mail)?.subscribe({
          next: (response: Password[]) => {
            this.buildListPassword(response);
          },
          error: () => {
            this.NgxSpinnerService.hide();
          },
          complete: () => {
            this.NgxSpinnerService.hide();
          },
        });
      } else {
        this.PasswordService.getPasswords(this.user.mail)?.subscribe({
          next: (response: Password[]) => {
            this.buildListPassword(response);
          },
          error: () => {
            this.NgxSpinnerService.hide();
          },
          complete: () => {
            this.NgxSpinnerService.hide();
          },
        });
      }
    }
  }

  public onSubmit() {
    if (this.step === 'UpdateAllData') {
      this.submitted = true;
      if (this.formPassword.invalid) {
        return;
      }

      if (this.submitted) {
        this.NgxSpinnerService.show();
        const password: Password = this.formPassword.value;
        if (this.sizePasswordTemp === password.sizePassword) {
          password.changePassword = false;
        } else {
          password.changePassword = true;
        }
        if (this.create) {
          password.changePassword = true;
          password.userMail = this.user?.mail;
          this.PasswordService.insert(password)?.subscribe({
            next: (response: Password) => {
              this.formatPassword(response);
              this.passwordList.push(response);
              this.setDataSource();
            },
            error: () => {
              this.modalRef?.hide();
              this.NgxSpinnerService.hide();
            },
            complete: () => {
              this.modalRef?.hide();
              this.NgxSpinnerService.hide();
            },
          });
        } else {
          password.id = this.idTemp;
          password.userMail = this.user?.mail;
          this.PasswordService.update(password)?.subscribe({
            next: (response: Password) => {
              this.buildListUpdatePassword(response);
            },
            error: () => {
              this.modalRef?.hide();
              this.NgxSpinnerService.hide();
            },
            complete: () => {
              this.modalRef?.hide();
              this.NgxSpinnerService.hide();
            },
          });
        }
      }
    } else {
      const passwordTemp: Password = this.formCustomPassword.value;
      if (this.passwordAux !== undefined) {
        this.passwordAux.userMail = this.user?.mail;
        this.passwordAux.changePassword = true;
        this.passwordAux.customPasswordChange = true;
        this.passwordAux.valuePassword = passwordTemp.valuePassword;
        this.PasswordService.update(this.passwordAux)?.subscribe({
          next: (response: Password) => {
            this.buildListUpdatePassword(response);
          },
          error: () => {
            this.modalRef?.hide();
            this.NgxSpinnerService.hide();
          },
          complete: () => {
            this.modalRef?.hide();
            this.NgxSpinnerService.hide();
          },
        });
      }
    }
  }

  private buildListUpdatePassword(response: Password): void {
    this.dataSource.data.forEach(elementPassword => {
      if (elementPassword.id === response.id) {
        elementPassword.platform = response.platform;
        elementPassword.user = response.user;
        elementPassword.url = response.url;
        elementPassword.valuePassword = response.valuePassword;
        elementPassword.sizePassword = response.sizePassword;
        elementPassword.valuePasswordFinal = this.replacePassword(elementPassword.valuePassword);
        elementPassword.passwordHide = true;
      }
    });
  }

  public editPassword(password: Password): void {
    this.sizePasswordTemp = password.sizePassword;
    this.idTemp = password.id;
    this.formPassword.patchValue({
      platform: password.platform,
      url: password.url,
      user: password.user,
      sizePassword: password.sizePassword,
    });
  }

  public deletePassword(password: Password): void {
    this.PasswordService.deletePasswords(password)?.subscribe(() => {
      this.passwordList = this.passwordList.filter(x => x.id !== password.id);
      const index = this.dataSource.data.findIndex(elementPassword => elementPassword.id === password.id);
      if (index !== -1) {
        this.dataSource.data.splice(index, 1);
        this.dataSource.data = this.dataSource.data.slice();
      }
    });
  }

  public sharedPassword(password: Password): void {
    this.NgxSpinnerService.show();
    password.shared = !password.shared;
    password.userMail = this.user?.mail;
    this.PasswordService.update(password)?.subscribe({
      next: (response: Password) => {
        this.buildListUpdatePassword(response);
      },
      error: () => {
        this.modalRef?.hide();
        this.NgxSpinnerService.hide();
      },
      complete: () => {
        this.modalRef?.hide();
        this.NgxSpinnerService.hide();
      },
    });
  }

  public newPasswordRandom(password: Password): void {
    this.NgxSpinnerService.show();
    password.changePassword = true;
    password.userMail = this.user?.mail;
    this.PasswordService.update(password)?.subscribe({
      next: (response: Password) => {
        this.buildListUpdatePassword(response);
      },
      error: () => {
        this.modalRef?.hide();
        this.NgxSpinnerService.hide();
      },
      complete: () => {
        this.modalRef?.hide();
        this.NgxSpinnerService.hide();
      },
    });
  }

  public showHidePassword(password: Password): void {
    password.passwordHide = !password.passwordHide;
    if (password.passwordHide) {
      password.valuePasswordFinal = this.replacePassword(password.valuePassword);
    } else {
      password.valuePasswordFinal = password.valuePassword;
    }
    if (!password.passwordHide) {
      this.dataSource.data.forEach(elementPassword => {
        if (elementPassword.id !== password.id) {
          elementPassword.passwordHide = true;
          elementPassword.valuePasswordFinal = this.replacePassword(elementPassword.valuePassword);
        }
      });
    }
  }

  public copyClipboard(text: string) {
    this.ClipboardService.copy(text);
  }
}
