// Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { Password } from '../models/password';

// Services
import { HttpService } from 'src/app/core/services/http/http.service';
import { RelativeUrl } from 'src/app/core/utilities/constants';

@Injectable({
  providedIn: 'root',
})
export class PasswordService extends HttpService {
  public constructor(private http: HttpClient) {
    super(http);
  }

  public getPasswords(mail: string): Observable<Password[]> | null {
    const service: string = '/user?user=' + mail;
    return this._requestGet(RelativeUrl.PASSWORD + service);
  }

  public getPasswordsAdmin(mail: string): Observable<Password[]> | null {
    const service: string = '/user/admin?user=' + mail;
    return this._requestGet(RelativeUrl.PASSWORD + service);
  }

  public getPasswordsShared(): Observable<Password[]> | null {
    const service: string = '/shared';
    return this._requestGet(RelativeUrl.PASSWORD + service);
  }

  public insert(password: Password): Observable<Password> | null {
    return this._requestPost(RelativeUrl.PASSWORD, password);
  }

  public update(password: Password): Observable<Password> | null {
    return this._requestPatch(RelativeUrl.PASSWORD, password);
  }

  public deletePasswords(password: Password): Observable<string> | null {
    const service: string = '?id=' + password.id;
    return this._requestDelete(RelativeUrl.PASSWORD + service);
  }
}
