import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { CONSTANTS, HttpCode } from '../utilities/constants';
import { TOKEN_AUTH } from '../modules/shared.module';
import { UtilsAuthService } from '../services/auth-utils/utils-auth.service';

@Injectable()
export class InterceptorHTTP extends UtilsAuthService implements HttpInterceptor {
  constructor(private router: Router) {
    super();
  }

  intercept(http: any, next: HttpHandler): Observable<HttpEvent<any>> {
    if (http instanceof HttpRequest) {
      let accessToken = sessionStorage.getItem('tokenAccess');
      if (accessToken === null || accessToken === undefined) {
        accessToken = this.calculateAccessToken(CONSTANTS.WEB_ADMINISTRATION_LITERAL);
      }

      let httpRequestTemp = http.clone({
        headers: http.headers
          .set(CONSTANTS.CONTENT_TYPE_LITERAL, CONSTANTS.CONTENT_TYPE_JSON)
          .set(CONSTANTS.TOKEN_AUTH_LITERAL, TOKEN_AUTH)
          .set(CONSTANTS.ACCESS_AUTH_LITERAL, accessToken),
      });

      return next.handle(httpRequestTemp).pipe(
        tap({
          next: () => {
            if (http instanceof HttpResponse) {
              const code = http.status;
              if (code === HttpCode.UNAUTHORIZED) {
                if (this.router.url === '/') {
                  this.router.navigate(['/']);
                  sessionStorage.removeItem('tokenAccess');
                  sessionStorage.removeItem('user');
                }
              }
            }
          },
          error: (error: HttpErrorResponse) => {
            const code = error.status;
            if (code === HttpCode.UNAUTHORIZED) {
              this.router.navigate(['/login']);
              sessionStorage.removeItem('tokenAccess');
              sessionStorage.removeItem('user');
            }
          },
        })
      );
    }
    return next.handle(http);
  }
}
