import { NgModule } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CustomPaginator } from '../utilities/CustomPaginatorConfiguration';

@NgModule({
  declarations: [],
  imports: [MatTableModule, MatSortModule, MatPaginatorModule, MatButtonModule, MatInputModule],
  exports: [MatTableModule, MatSortModule, MatPaginatorModule, MatButtonModule, MatInputModule],
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }],
  bootstrap: [],
})
export class MaterialModule {}
