<div class="h-100">
  <header class="py-3 border-bottom shadow"></header>
  <!--sidebar-->
  <c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible>
    <c-sidebar-brand
      [brandFull]="{
        src: 'assets/img/brand/coreui-angular.svg',
        width: 200,
        height: 46,
        alt: 'CoreUI Logo'
      }"
      [brandNarrow]="{
        src: 'assets/img/brand/coreui-signet-white.svg',
        width: 46,
        height: 46,
        alt: 'CoreUI Logo'
      }"
      routerLink="./">
    </c-sidebar-brand>
    <c-sidebar-nav [navItems]="navItems" dropdownMode="closeInactive"> </c-sidebar-nav>
    <c-sidebar-footer>
      <c-form-check [switch]="true">
        <input cFormCheckInput type="checkbox" (change)="onCheckboxChange()" />
        <label cFormCheckLabel>Mantener sesión activa</label>
      </c-form-check>
    </c-sidebar-footer>
  </c-sidebar>
  <!--main-->
  <div class="container-fluid">
    <div class="row">
      <div class="col padding-content">
        <div class="wrapper bg-light dark:bg-transparent py-2">
          <div class="body flex-grow-1 px-3">
            <c-container breakpoint="lg" class="h-auto">
              <router-outlet></router-outlet>
            </c-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
