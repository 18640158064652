import { NgModule } from '@angular/core';
import { BootstrapCustomModule } from './boostrap-custom.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreUIModule } from './coreui.module';
import { environment } from 'src/environments/environment';
import { ClipboardModule } from 'ngx-clipboard';
import { MaterialModule } from './material.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IconModule } from '@coreui/icons-angular';

@NgModule({
  declarations: [],
  imports: [
    BootstrapCustomModule,
    NgxSpinnerModule,
    CoreUIModule,
    ClipboardModule,
    MaterialModule,
    TooltipModule.forRoot(),
    IconModule,
  ],
  exports: [
    BootstrapCustomModule,
    NgxSpinnerModule,
    CoreUIModule,
    ClipboardModule,
    MaterialModule,
    TooltipModule,
    IconModule,
  ],
  providers: [],
  bootstrap: [],
})
export class SharedModule {}

export const BASE_URL = environment.urlBackJava;
export const TOKEN_AUTH = environment.tokenAuth;
