<mat-form-field appearance="standard">
  <mat-label>filtro</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Texto a buscar" #input />
</mat-form-field>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class="table-streamers" matSort hover>
    <caption>
      Tabla de streamers registrados
    </caption>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.id }}</td>
    </ng-container>
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Activo</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.active }}</td>
    </ng-container>
    <ng-container matColumnDef="streamerName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Streamer</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.streamerName }}</td>
    </ng-container>
    <ng-container matColumnDef="direct">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>direct</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.direct }}</td>
    </ng-container>
    <ng-container matColumnDef="twitchUserId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>twitchUserId</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.twitchUserId }}</td>
    </ng-container>
    <ng-container matColumnDef="twitchUserLogin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>twitchUserLogin</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.twitchUserLogin }}</td>
    </ng-container>
    <ng-container matColumnDef="twitchName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>twitchName</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.twitchName }}</td>
    </ng-container>
    <ng-container matColumnDef="urlTwitter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>urlTwitter</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.urlTwitter }}</td>
    </ng-container>
    <ng-container matColumnDef="urlYoutube">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>urlYoutube</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.urlYoutube }}</td>
    </ng-container>
    <ng-container matColumnDef="urlFacebook">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>urlFacebook</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.urlFacebook }}</td>
    </ng-container>
    <ng-container matColumnDef="urlInstagram">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>urlInstagram</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.urlInstagram }}</td>
    </ng-container>
    <ng-container matColumnDef="urlServerDiscord">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>urlServerDiscord</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.urlServerDiscord }}</td>
    </ng-container>
    <ng-container matColumnDef="thumbnailUrl">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>thumbnailUrl</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.thumbnailUrl }}</td>
    </ng-container>
    <ng-container matColumnDef="userImg">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>userImg</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.userImg }}</td>
    </ng-container>
    <ng-container matColumnDef="customImg">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>customImg</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.customImg }}</td>
    </ng-container>
    <ng-container matColumnDef="mail">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>mail</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.mail }}</td>
    </ng-container>
    <ng-container matColumnDef="lastBroadcastTwitch">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>lastBroadcastTwitch</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.lastBroadcastTwitch }}</td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>createdDate</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.createdDate }}</td>
    </ng-container>
    <ng-container matColumnDef="tweetsent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>tweetsent</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.tweetsent }}</td>
    </ng-container>
    <ng-container matColumnDef="languageValue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>languageValue</th>
      <td mat-cell *matCellDef="let streamer">{{ streamer.languageValue }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        Acciones
        <!-- <button mat-raised-button (click)="openModal(modalEditPassword, false, true, null)" class="new-button">
          Nuevo
        </button> -->
      </th>
      <td mat-cell *matCellDef="let streamer">asdasd</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro "{{ input.value }}"</td>
    </tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100]"
    [pageSize]="10"
    [length]="dataSource.data.length"></mat-paginator>
</div>
