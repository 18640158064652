import { NgModule } from '@angular/core';
import { SidebarModule, TableModule, UtilitiesModule, FormModule } from '@coreui/angular';

@NgModule({
  declarations: [],
  imports: [SidebarModule, TableModule, UtilitiesModule, FormModule],
  exports: [SidebarModule, TableModule, UtilitiesModule, FormModule],
  providers: [],
  bootstrap: [],
})
export class CoreUIModule {}
