import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { BASE_URL } from '../../modules/shared.module';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly _http: HttpClient;

  constructor(http: HttpClient) {
    this._http = http;
  }

  protected _requestGetAccess<Type>(relativeUrl: string): Observable<Type> {
    const url = BASE_URL + relativeUrl;
    return this._http.get<Type>(url, { headers: new HttpHeaders() }).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }

  protected _requestGet<Type>(relativeUrl: string): Observable<Type> {
    const url = BASE_URL + relativeUrl;
    return this._http.get<Type>(url, { headers: new HttpHeaders() }).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }

  protected _requestPost<Type>(relativeUrl: string, args: any): Observable<Type> {
    const url = BASE_URL + relativeUrl;
    return this._http.post<Type>(url, args, { headers: new HttpHeaders() }).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }

  protected _requestPut<Type>(relativeUrl: string, args?: any): Observable<Type> {
    const url = BASE_URL + relativeUrl;
    return this._http.put<Type>(url, args, { headers: new HttpHeaders() }).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }

  protected _requestPatch<Type>(relativeUrl: string, args: any): Observable<Type> {
    const url = BASE_URL + relativeUrl;
    return this._http.patch<Type>(url, args, { headers: new HttpHeaders() }).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }

  protected _requestDelete<Type>(relativeUrl: string): Observable<Type> {
    const url = BASE_URL + relativeUrl;
    return this._http.delete<Type>(url, { headers: new HttpHeaders() }).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }

  protected _requestDeleteWithBody<Type>(relativeUrl: string, args: any): Observable<Type> {
    const url = BASE_URL + relativeUrl;
    return this._http.delete<Type>(url, { headers: new HttpHeaders(), body: args }).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }
}
