import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/services/http/http.service';
import { RelativeUrl } from 'src/app/core/utilities/constants';

// Modelos
import { Access } from '../models/access';
import { Login } from '../models/login';
import { User } from '../models/user';

@Injectable()
export class AuthService extends HttpService {
  public constructor(private http: HttpClient) {
    super(http);
  }

  // Auth Token
  public authToken(): Observable<Access> {
    return this._requestGetAccess(RelativeUrl.ACCESS_ADMINISTRATION);
  }

  // Login
  public login(login: Login): Observable<User> {
    return this._requestPost(RelativeUrl.ACCESS_LOGIN_ADMINISTRATION, login);
  }

  public resetPassword(mail: string): Observable<any> {
    return this._requestPut(RelativeUrl.ACCESS_LOGIN_ADMINISTRATION_RESETPASSWORD + '?user=' + mail);
  }
}
