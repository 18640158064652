// Modules
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './core/modules/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './core/route/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { IconSetService } from '@coreui/icons-angular';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/main/login.component';
import { MainComponent } from './views/main/main/main.component';
import { SettingsComponent } from './views/main/components/general-management/settings/main/settings.component';
import { NavServersComponent } from './views/main/components/stalking-dragons/servers/components/nav-servers/nav-servers.component';
import { ServersComponent } from './views/main/components/stalking-dragons/servers/main/servers.component';
import { ConfigFilesComponent } from './views/main/components/utilities/config-files/main/config-files.component';
import { ConfigPasswordComponent } from './views/main/components/utilities/config-password/main/config-password.component';
import { BackSkdLogComponent } from './views/main/components/management-apps/components/back-skd/components/back-skd-log/back-skd-log.component';
import { OverturnLogComponent } from './views/main/components/management-apps/components/overturn/components/overturn-log/overturn-log.component';
import { PasswordSharedComponent } from './views/main/components/utilities/password-shared/main/password-shared.component';
import { TwitterLogComponent } from './views/main/components/management-apps/components/twitter/components/twitter-log/twitter-log.component';
import { TwitchLogComponent } from './views/main/components/management-apps/components/twitch/components/twitch-log/twitch-log.component';
import { ControlLogComponent } from './views/main/components/management-apps/components/control/components/control-log/control-log.component';
import { StreamersComponent } from './views/main/components/general-management/streamers/main/streamers.component';

// Services
import { AuthService } from './views/login/services/auth.service';
import { InterceptorHTTP } from './core/interceptor/interceptor-http';
import { UtilsAuthService } from './core/services/auth-utils/utils-auth.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    NavServersComponent,
    ServersComponent,
    ConfigPasswordComponent,
    PasswordSharedComponent,
    ConfigFilesComponent,
    SettingsComponent,
    BackSkdLogComponent,
    OverturnLogComponent,
    TwitterLogComponent,
    TwitchLogComponent,
    ControlLogComponent,
    StreamersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthService,
    IconSetService,
    UtilsAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (router: Router) {
        return new InterceptorHTTP(router);
      },
      multi: true,
      deps: [Router],
    },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
