import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { TwitchService } from '../../service/twitch.service';

@Component({
  selector: 'app-twitch-log',
  templateUrl: './twitch-log.component.html',
  styleUrls: ['./twitch-log.component.css'],
  providers: [TwitchService],
})
export class TwitchLogComponent implements OnInit, OnDestroy {
  public logLines: string[] = [];
  private intervalSubscription: Subscription | undefined;

  @ViewChild('console') consoleContentOverturnLog: ElementRef | undefined;
  constructor(private TwitchService: TwitchService) {}

  ngOnInit() {
    const intervalObservable = interval(2000);
    this.intervalSubscription = intervalObservable.subscribe(() => {
      this.TwitchService.getLog()?.subscribe((res: any[]) => {
        this.logLines.push(...res);
        this.logLines = [...new Set(this.logLines)];
      });
    });
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}
