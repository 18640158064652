import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/services/http/http.service';
import { RelativeUrl } from 'src/app/core/utilities/constants';

@Injectable()
export class TwitterService extends HttpService {
  public constructor(private http: HttpClient) {
    super(http);
  }

  public getLog(): Observable<any[]> | null {
    return this._requestGet(RelativeUrl.GET_TWITTER_LOG);
  }
}
