import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SyncService } from 'src/app/core/services/sync/sync.service';
import { Utilities } from 'src/app/core/utilities/utilities';
import { Access } from '../models/access';
import { Login } from '../models/login';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { UtilsAuthService } from '../../../core/services/auth-utils/utils-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthService],
})
export class LoginComponent extends UtilsAuthService implements OnInit {
  public formLogin: FormGroup = new FormGroup({
    mailLogin: new FormControl('', [Validators.required, Validators.email]),
    passwordLogin: new FormControl('', [Validators.required]),
  });
  public submitted = false;

  constructor(
    private NgxSpinnerService: NgxSpinnerService,
    private AuthService: AuthService,
    private SyncService: SyncService,
    private Router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('tokenAccess');
  }

  get form() {
    return this.formLogin.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.formLogin.invalid) {
      return;
    }
    if (this.submitted) {
      this.NgxSpinnerService.show();
      sessionStorage.removeItem('tokenAccess');
      sessionStorage.removeItem('user');
      this.AuthService.authToken().subscribe({
        next: (responseAccess: Access) => {
          const login: Login = this.formLogin.value;
          login.passwordLogin = Utilities.toBase64(login.passwordLogin);
          sessionStorage.setItem('tokenAccess', responseAccess.tokenAccess);
          this.AuthService.login(login).subscribe({
            next: (responseUsuario: User) => {
              const sessionStorageString = Utilities.transcribeUser(responseUsuario);
              sessionStorage.setItem('user', sessionStorageString);
              this.SyncService.setSession(sessionStorageString);
              this.Router.navigate(['/main/config-password']);
            },
            error: () => {
              this.NgxSpinnerService.hide();
            },
          });
        },
        error: () => {
          this.NgxSpinnerService.hide();
        },
        complete: () => {
          this.NgxSpinnerService.hide();
        },
      });
    }
  }

  public resetPassword(): void {
    const mail = prompt('¿Como es tu dirección EMAIL?');
    if (mail !== null) {
      this.AuthService.resetPassword(mail).subscribe({});
    }
  }
}
