import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ControlService } from '../../service/control.service';

@Component({
  selector: 'app-control-log',
  templateUrl: './control-log.component.html',
  styleUrls: ['./control-log.component.css'],
  providers: [ControlService],
})
export class ControlLogComponent implements OnInit, OnDestroy {
  public logLines: string[] = [];
  private intervalSubscription: Subscription | undefined;

  @ViewChild('console') consoleContentOverturnLog: ElementRef | undefined;
  constructor(private ControlService: ControlService) {}

  ngOnInit() {
    const intervalObservable = interval(2000);
    this.intervalSubscription = intervalObservable.subscribe(() => {
      this.ControlService.getLog()?.subscribe((res: any[]) => {
        this.logLines.push(...res);
        this.logLines = [...new Set(this.logLines)];
      });
    });
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}
