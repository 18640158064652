import { Component } from '@angular/core';
import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './core/icons/icon-subset';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Administración SKD';
  constructor(private iconSetService: IconSetService) {
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }
}
