import {
  cilFolderOpen,
  cibKeycdn,
  cilApps,
  cibTwitter,
  cilAvTimer,
  cibAtom,
  cilSettings,
  cilAccountLogout,
  cilAppsSettings,
  cibServerFault,
  cibTwitch,
} from '@coreui/icons';

export const iconSubset = {
  cilFolderOpen,
  cibKeycdn,
  cilApps,
  cibTwitter,
  cilAvTimer,
  cibAtom,
  cilSettings,
  cilAccountLogout,
  cilAppsSettings,
  cibServerFault,
  cibTwitch,
};

export enum IconSubset {
  cilFolderOpen = 'cilFolderOpen',
  cibKeycdn = 'cibKeycdn',
  cilApps = 'cilApps',
  cibTwitter = 'cibTwitter',
  cilAvTimer = 'cilAvTimer',
  cibAtom = 'cibAtom',
  cilSettings = 'cilSettings',
  cilAccountLogout = 'cilAccountLogout',
  cilAppsSettings = 'cilAppsSettings',
  cibServerFault = 'cibServerFault',
  cibTwitch = 'cibTwitch',
}
