+
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class="table-config-files" matSort hover>
    <ng-container matColumnDef="nameFile">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let fileSystem">
        <span *ngIf="fileSystem.backOff && !fileSystem.backOffActive">{{ fileSystem.nameFile }}</span>
        <button
          *ngIf="fileSystem.backOff && fileSystem.backOffActive"
          type="button"
          class="btn btn-link"
          (click)="returnFolder()"
          placement="top"
          ngbTooltip="Retroceder una carpeta">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-return-left"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
          </svg>
        </button>
        <button
          *ngIf="fileSystem.directory && !fileSystem.empty && !fileSystem.backOff"
          type="button"
          class="btn btn-link"
          (click)="openFolder(fileSystem)"
          placement="top"
          ngbTooltip="Acceder a la carpeta {{ fileSystem.nameFile }}">
          {{ fileSystem.nameFile }}
        </button>
        <span *ngIf="fileSystem.directory && fileSystem.empty && !fileSystem.backOff">{{ fileSystem.nameFile }}</span>
        <span *ngIf="!fileSystem.directory && !fileSystem.backOff">{{ fileSystem.nameFile }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="directory">
      <th mat-header-cell *matHeaderCellDef>Directorio</th>
      <td mat-cell *matCellDef="let fileSystem">
        {{ fileSystem.backOff ? pathInServer : fileSystem.directory ? 'Sí' : 'No' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="empty">
      <th mat-header-cell *matHeaderCellDef>Vacío</th>
      <td mat-cell *matCellDef="let fileSystem">{{ fileSystem.backOff ? '' : fileSystem.empty ? 'Sí' : 'No' }}</td>
    </ng-container>
    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef>Tamaño</th>
      <td mat-cell *matCellDef="let fileSystem">{{ fileSystem.directory ? '' : fileSystem.size }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let fileSystem">
        <button
          *ngIf="fileSystem.backOff"
          type="button"
          class="btn btn-link"
          (click)="createFolder(modalConfigFiles)"
          placement="top"
          ngbTooltip="Crear una carpeta">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-folder-plus"
            viewBox="0 0 16 16">
            <path
              d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z" />
            <path
              d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z" />
          </svg>
        </button>
        <button
          *ngIf="!fileSystem.backOff && fileSystem.empty"
          type="button"
          class="btn btn-link"
          (click)="createFolderSelected(modalConfigFiles, fileSystem)"
          placement="top"
          ngbTooltip="Crear una carpeta">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-folder-plus"
            viewBox="0 0 16 16">
            <path
              d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z" />
            <path
              d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z" />
          </svg>
        </button>
        <button
          *ngIf="fileSystem.directory && !fileSystem.backOff"
          type="button"
          class="btn btn-link"
          (click)="uploadFileToFolder(modalConfigFiles, fileSystem)"
          placement="top"
          ngbTooltip="Subir un archivo a la carpeta {{ fileSystem.nameFile }}">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-cloud-arrow-up"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
            <path
              d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
          </svg>
        </button>
        <button
          *ngIf="!fileSystem.directory && !fileSystem.backOff"
          type="button"
          class="btn btn-link"
          (click)="downloadFile(fileSystem)"
          placement="top"
          ngbTooltip="Descargar archivo: {{ fileSystem.nameFile }}">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-cloud-download"
            viewBox="0 0 16 16">
            <path
              d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
            <path
              d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
          </svg>
        </button>
        <button
          *ngIf="(!fileSystem.directory && !fileSystem.backOff) || (fileSystem.directory && fileSystem.empty)"
          type="button"
          class="btn btn-link"
          (click)="deletefile(fileSystem)"
          placement="top"
          ngbTooltip="Borrar archivo: {{ fileSystem.nameFile }}">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-trash2-fill"
            viewBox="0 0 16 16">
            <path
              d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z" />
          </svg>
        </button>
        <button
          *ngIf="
            !fileSystem.directory &&
            fileSystem.img &&
            fileSystem.path !== undefined &&
            fileSystem.path.includes(pathImg) &&
            !fileSystem.backOff
          "
          type="button"
          class="btn btn-link"
          (click)="openFile(fileSystem)"
          placement="top"
          ngbTooltip="Ver archivo {{ fileSystem.nameFile }} en una nueva pestaña">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-box-arrow-up-right"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
            <path
              fill-rule="evenodd"
              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
          </svg>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef>Fecha creación</th>
      <td mat-cell *matCellDef="let fileSystem">{{ fileSystem.creationDate }}</td>
    </ng-container>
    <ng-container matColumnDef="lastAccessDate">
      <th mat-header-cell *matHeaderCellDef>Fecha último acceso</th>
      <td mat-cell *matCellDef="let fileSystem">{{ fileSystem.lastAccessDate }}</td>
    </ng-container>
    <ng-container matColumnDef="lastModifiedDate">
      <th mat-header-cell *matHeaderCellDef>Fecha última modificación</th>
      <td mat-cell *matCellDef="let fileSystem">{{ fileSystem.lastModifiedDate }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<!-- Modal -->
<ng-template #modalConfigFiles>
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="modal-title">{{ titleModal }}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="bodyModal">
      <div [ngSwitch]="step">
        <div *ngSwitchCase="'UploadFile'">
          <input class="form-control" id="fileUpload" name="fileUpload" type="file" (change)="onFileSelected($event)" />
        </div>
        <div *ngSwitchCase="'CreateFolder'">
          <span class="input-group-text rounded-0" id="basic-addon-platform">Nueva carpeta</span>
          <input
            type="text"
            class="form-control rounded-0"
            placeholder="Nombre de la carpeta"
            aria-label="platform"
            aria-describedby="basic-addon-platform"
            [(ngModel)]="newFolderToCreate" />
        </div>
        <div *ngSwitchCase="'CreateFolderSelected'">
          <span class="input-group-text rounded-0" id="basic-addon-platform">Nueva carpeta</span>
          <input
            type="text"
            class="form-control rounded-0"
            placeholder="Nombre de la carpeta"
            aria-label="platform"
            aria-describedby="basic-addon-platform"
            [(ngModel)]="newFolderToCreate" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div id="ModalFooter">
      <button type="submit" (click)="onSubmit()" class="btn btn-success text-uppercase mb-2 rounded-pill shadow-sm">
        {{ buttonModal }}
      </button>
    </div>
  </div>
</ng-template>
