// Modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { MainComponent } from 'src/app/views/main/main/main.component';
import { LoginComponent } from '../../views/login/main/login.component';
import { SettingsComponent } from 'src/app/views/main/components/general-management/settings/main/settings.component';
import { ConfigFilesComponent } from 'src/app/views/main/components/utilities/config-files/main/config-files.component';
import { ConfigPasswordComponent } from 'src/app/views/main/components/utilities/config-password/main/config-password.component';
import { PasswordSharedComponent } from 'src/app/views/main/components/utilities/password-shared/main/password-shared.component';
import { BackSkdLogComponent } from 'src/app/views/main/components/management-apps/components/back-skd/components/back-skd-log/back-skd-log.component';
import { OverturnLogComponent } from 'src/app/views/main/components/management-apps/components/overturn/components/overturn-log/overturn-log.component';
import { TwitterLogComponent } from 'src/app/views/main/components/management-apps/components/twitter/components/twitter-log/twitter-log.component';
import { TwitchLogComponent } from 'src/app/views/main/components/management-apps/components/twitch/components/twitch-log/twitch-log.component';
import { ControlLogComponent } from 'src/app/views/main/components/management-apps/components/control/components/control-log/control-log.component';
import { StreamersComponent } from 'src/app/views/main/components/general-management/streamers/main/streamers.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'main',
    component: MainComponent,
    children: [
      { path: '', redirectTo: '/main', pathMatch: 'full' },
      { path: 'config-password', component: ConfigPasswordComponent },
      { path: 'password-shared', component: PasswordSharedComponent },
      { path: 'config-files', component: ConfigFilesComponent },
      { path: 'streamers-twitch', component: StreamersComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'back-skd-log', component: BackSkdLogComponent },
      { path: 'overturn-log', component: OverturnLogComponent },
      { path: 'twitter-log', component: TwitterLogComponent },
      { path: 'twitch-log', component: TwitchLogComponent },
      { path: 'control-log', component: ControlLogComponent },
    ],
  },
];

@NgModule({ imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })], exports: [RouterModule] })
export class AppRoutingModule {}
