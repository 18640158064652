import { Injectable } from '@angular/core';
import { Utilities } from 'src/app/core/utilities/utilities';

@Injectable({
  providedIn: 'root',
})
export class UtilsAuthService {
  createDateAsUTC(date: Date): Date {
    const d = new Date(date);
    const utcDate = new Date(
      d.getUTCFullYear(),
      d.getUTCMonth(),
      d.getUTCDate(),
      d.getUTCHours(),
      d.getUTCMinutes(),
      d.getUTCSeconds(),
      d.getUTCMilliseconds()
    );
    return utcDate;
  }

  public calculateAccessToken(nameAPP: string): string {
    const securityData: string = 'LOCO';
    const tokenSecurity: string = 'Stalking Dragons';
    let token = securityData + '/' + tokenSecurity + '_' + nameAPP + '/' + securityData;
    return Utilities.toBase64(token);
  }
}
