import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  private varSession$ = new Subject<string>();
  private session: string | undefined;

  public constructor() {}

  public setSession(usuario: string): void {
    this.session = usuario;
    this.varSession$.next(this.session);
  }

  public getSession$(): Observable<string> {
    return this.varSession$.asObservable();
  }
  public removeSession(): void {
    this.session = undefined;
    this.varSession$.next('');
  }
}
